<!--
Document contains several places where events are implemented twice.
Duplication is necessary at the time of development to ensure support on all platforms.
Therefore repeated triggering of single events is accepted.
 e.g. pointermove & touchmove in #svgContainer
-->
<canvas #hiddenCanvas id='hiddenCanvas' style="display: none"></canvas>
<div class="itemAligner" style="position: relative">
  <ng-container *ngIf="userValidation$ && this.cardWeb">
    <em-status-display style="position: absolute; bottom: 0; pointer-events: none;"></em-status-display>
    <div id="editor"
         cdkDropList
         cdkDropListAutoScrollDisabled
         cdkDropListSortingDisabled
         cdkDropListConnectedTo="defaultDeck"
         (cdkDropListDropped)="dropNewCard($event)">

      <div *cdkDragPlaceholder></div>

      <svg #svgContainer
           id="svgeditor"
           viewBox="10000,6000,800,800" height="90vh" width="100vw"
           (mousedown)="startDragGrid($event)"
           (pointermove)="doDragGrid($event)"
           (wheel)="zoom($event)"
           (pinch)="zoom($event)"
           (click)="deselectAllCards(); deselectAllConnections()"
           (pointerdown)="startDragGrid($event);"
           (touchmove)="doDragGrid($event)">
        <defs>
          <svg id="plusSign">
            <circle [attr.cx]="20" [attr.cy]="20" r="17" fill="white" stroke="black" stroke-width="1"
                    stroke-opacity="0.8" fill-opacity="0.8"/>
            <line [attr.x1]="10" [attr.y1]="20" [attr.x2]="30" [attr.y2]="20" stroke="black" stroke-opacity="0.8"/>
            <line [attr.x1]="20" [attr.y1]="10" [attr.x2]="20" [attr.y2]="30" stroke="black" stroke-opacity="0.8"/>
          </svg>
          <pattern id="tenthGrid" width="10" height="10" patternUnits="userSpaceOnUse">
            <path d="M 10 0 L 0 0 0 10" fill="none" stroke="silver" stroke-width="0.5"/>
          </pattern>
          <pattern id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
            <rect width="100" height="100" fill="url(#tenthGrid)"/>
            <path d="M 100 0 L 0 0 0 100" fill="none" stroke="gray" stroke-width="1"/>
          </pattern>
        </defs>
        <rect id="background" width="20000px" height="13000px" y="-1" x="-1" fill="url(#grid)" stroke="grey"/>
        <rect
          class="hoveredGrid"
          *ngIf="showHoverCard"
          [attr.x]="hoveredGridX"
          [attr.y]="hoveredGridY"
          width="208pt"
          height="312pt"
          rx="15"
          fill-opacity="0.2"
          fill="#0c84c9"
        ></rect>
        <ng-container *ngIf="this.gameDesign">
          <ng-container
            *ngIf="isConnectionSelected && (gameDesign.gameDesignRelations.length || gameDesign.gameDesignProblemRelations.length)">

            <ng-container *ngIf="this.calculateRelationCoordinates(selectedConnection.sourceId, selectedConnection.targetId).pos==='left' ||
            this.calculateRelationCoordinates(selectedConnection.sourceId, selectedConnection.targetId).pos==='right'">
              <a>
                <svg class="deleteSymbol" width="22" height="22" viewBox="-2 -2 24 24"
                     (click)="removeConnection(); $event.stopImmediatePropagation()"
                     [@fadeInOut] style="cursor: pointer"
                     [attr.x]="this.calculateRelationDeleteCoordinates().x"
                     [attr.y]="this.calculateRelationDeleteCoordinates().y">
                  <rect width="22" height="22" fill-opacity="0"/>
                  <line x1="1" y1="2" x2="18" y2="18" stroke="#e62727" stroke-width="5"/>
                  <line x1="1" y1="18" x2="18" y2="2" stroke="#e62727" stroke-width="5"/>
                </svg>
              </a>
              <a (click)="openRelationNoteMenu(selectedConnection.sourceId, selectedConnection.targetId)">
                <svg xmlns="http://www.w3.org/2000/svg"
                     [attr.x]="this.calculateRelationNoteMenuCoordinates().x"
                     [attr.y]="this.calculateRelationNoteMenuCoordinates().y"
                     style="cursor: pointer" height="40px" width="40px" viewBox="0 -960 960 960" fill="#545454">
                  <rect rx="100" y="-960" height="960px" width="960px" fill="#4b4b4b" [@fadeInOut]
                        stroke-opacity="1" fill-opacity="0.9"/>
                  <path xmlns="http://www.w3.org/2000/svg" d="M200-200h360v-200h200v-360H200v560Zm0 80q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v400L600-120H200Zm80-280v-80h200v80H280Zm0-160v-80h400v80H280Zm-80 360v-560 560Z" fill="white"/>
                </svg>
              </a>
            </ng-container>
            <ng-container *ngIf="this.calculateRelationCoordinates(selectedConnection.sourceId, selectedConnection.targetId).pos==='bottom'">
              <a>
                <svg class="deleteSymbol" width="22" height="22" viewBox="-2 -2 24 24"
                     (click)="removeConnection(); $event.stopImmediatePropagation()"
                     [attr.x]="this.calculateRelationDeleteCoordinates('bottom').x"
                     [@fadeInOut] style="cursor: pointer"
                     [attr.y]="this.calculateRelationDeleteCoordinates('bottom').y">
                  <rect width="22" height="22" fill-opacity="0"/>
                  <line x1="1" y1="2" x2="18" y2="18" stroke="#e62727" stroke-width="5"/>
                  <line x1="1" y1="18" x2="18" y2="2" stroke="#e62727" stroke-width="5"/>
                </svg>
              </a>
              <a (click)="openRelationNoteMenu(selectedConnection.sourceId, selectedConnection.targetId)">
                <svg xmlns="http://www.w3.org/2000/svg"
                     [attr.x]="this.calculateRelationNoteMenuCoordinates('bottom').x"
                     [attr.y]="this.calculateRelationNoteMenuCoordinates('bottom').y"
                     style="cursor: pointer" height="40px" width="40px" viewBox="0 -960 960 960" fill="#545454">
                  <rect rx="100" y="-960" height="960px" width="960px" fill="#4b4b4b" [@fadeInOut]
                        stroke-opacity="1" fill-opacity="0.9"/>
                  <path xmlns="http://www.w3.org/2000/svg" d="M200-200h360v-200h200v-360H200v560Zm0 80q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v400L600-120H200Zm80-280v-80h200v80H280Zm0-160v-80h400v80H280Zm-80 360v-560 560Z" fill="white"/>
                </svg>
              </a>
            </ng-container>
          </ng-container>

          <!-- currently Decktype is not working in selectConnection(), therefore a string is passed -->
          <ng-container *ngFor="let relation of gameDesign.gameDesignRelations">
            <rect *ngIf="this.calculateRelationCoordinates(relation.sourceId, relation.targetId).pos==='left' ||
                this.calculateRelationCoordinates(relation.sourceId, relation.targetId).pos==='right'"
                  [ngClass]="{'selectedConnection': selectedConnection.sourceId === relation.sourceId && selectedConnection.targetId === relation.targetId && isConnectionSelected}"
                  style="height: 38px; width: 141px; fill: #D9AB82"
                  [attr.x]="this.calculateRelationCoordinates(relation.sourceId, relation.targetId).x"
                  [attr.y]="this.calculateRelationCoordinates(relation.sourceId, relation.targetId).y"
                  [attr.sourceId]="relation.sourceId"
                  [attr.targetId]="relation.targetId"
                  (click)="selectConnection(relation.sourceId, relation.targetId,
                  this.calculateRelationCoordinates(relation.sourceId, relation.targetId).x,
                  this.calculateRelationCoordinates(relation.sourceId, relation.targetId).y,
                  relation.relationItemId, 'pattern'); $event.stopImmediatePropagation()">
            </rect>
            <rect *ngIf="this.calculateRelationCoordinates(relation.sourceId, relation.targetId).pos==='bottom'"
                  [ngClass]="{'selectedConnection': selectedConnection.sourceId === relation.sourceId && selectedConnection.targetId === relation.targetId && isConnectionSelected}"
                  style="height: 94px; width: 45px; fill: #D9AB82"
                  [attr.x]="this.calculateRelationCoordinates(relation.sourceId, relation.targetId).x"
                  [attr.y]="this.calculateRelationCoordinates(relation.sourceId, relation.targetId).y"
                  [attr.sourceId]="relation.sourceId"
                  [attr.targetId]="relation.targetId"
                  (click)="selectConnection(relation.sourceId, relation.targetId,
                  this.calculateRelationCoordinates(relation.sourceId, relation.targetId).x,
                  this.calculateRelationCoordinates(relation.sourceId, relation.targetId).y,
                  relation.relationItemId, 'pattern'); $event.stopImmediatePropagation()">
            </rect>
          </ng-container>
          <ng-container *ngFor="let relation of gameDesign.gameDesignProblemRelations">
            <rect *ngIf="this.calculateRelationCoordinates(relation.sourceId, relation.targetId).pos==='left' ||
                this.calculateRelationCoordinates(relation.sourceId, relation.targetId).pos==='right'"
                  [ngClass]="{'selectedConnection': selectedConnection.sourceId === relation.sourceId && selectedConnection.targetId === relation.targetId && isConnectionSelected}"
                  style="height: 38px; width: 141px; fill: #D9AB82"
                  [attr.x]="this.calculateRelationCoordinates(relation.sourceId, relation.targetId).x"
                  [attr.y]="this.calculateRelationCoordinates(relation.sourceId, relation.targetId).y"
                  [attr.sourceId]="relation.sourceId"
                  [attr.targetId]="relation.targetId"
                  (click)="selectConnection(relation.sourceId, relation.targetId,
                  this.calculateRelationCoordinates(relation.sourceId, relation.targetId).x,
                  this.calculateRelationCoordinates(relation.sourceId, relation.targetId).y,
                  relation.relationItemId, 'problem'); $event.stopImmediatePropagation()">
            </rect>
            <rect *ngIf="this.calculateRelationCoordinates(relation.sourceId, relation.targetId).pos==='bottom'"
                  [ngClass]="{'selectedConnection': selectedConnection.sourceId === relation.sourceId && selectedConnection.targetId === relation.targetId && isConnectionSelected}"
                  style="height: 94px; width: 45px; fill: #D9AB82"
                  [attr.x]="this.calculateRelationCoordinates(relation.sourceId, relation.targetId).x"
                  [attr.y]="this.calculateRelationCoordinates(relation.sourceId, relation.targetId).y"
                  [attr.sourceId]="relation.sourceId"
                  [attr.targetId]="relation.targetId"
                  (click)="selectConnection(relation.sourceId, relation.targetId,
                  this.calculateRelationCoordinates(relation.sourceId, relation.targetId).x,
                  this.calculateRelationCoordinates(relation.sourceId, relation.targetId).y,
                  relation.relationItemId, 'problem'); $event.stopImmediatePropagation()">
            </rect>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="cardWeb">
          <ng-container *ngFor="let cw of cardWeb.cards">
            <foreignObject #foreignObj
                           class="editorElement"
                           (click)="selectCard(foreignObj, cw); $event.stopImmediatePropagation()"
                           (pointerdown)="startDragElement($event, foreignObj, cw); selectCard(foreignObj, cw);"
                           (touchstart)="startDragElement($event, foreignObj, cw); selectCard(foreignObj, cw);"
                           style="width: 208pt; height: 312pt;"
                           [attr.id]="cw.id"
                           [attr.x]="this.calculateCardXCoordinates(cw.posX)"
                           [attr.y]="this.calculateCardYCoordinates(cw.posY, cw.posX)">
              <em-pattern-card [rightSidenav]="rightSidenav"
                               [cardPatternId]="cw.id"
                               [cardDeckId]="cw.cardDeckId"
                               [cardDeckType]="cw.cardDeckType"
                               [ngClass]="{'selected': selectedCardId === cw.id}">
              </em-pattern-card>
             <ng-container *ngIf="hasCardNote(cw.id)">
              <em-note-sticky
                [text]="this.getCardNoteText(cw.id)"
                [width]="185"
                [maxCollapsedHeight]="135"
                [maintainMinimumHeight]="true"
                [left]="2.35"
                [noteType]="this.getCardNoteType(cw.id)"
                [maxExpandedHeight]="230"
                (pointerenter)="$event.stopImmediatePropagation()"
                style="position:absolute; bottom: 1rem"
              >
              </em-note-sticky>
              </ng-container>
            </foreignObject>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="this.gameDesign">
          <ng-container *ngFor="let relation of getGameDesignProblemRelations()">
            <ng-container *ngIf="hasRelationNote(relation)">
              <foreignObject class="relation-note-container" width="185" height="90" style="z-index:10"
                             [attr.x]="calculateRelationNoteCoordinate(relation).x"
                             [attr.y]="calculateRelationNoteCoordinate(relation).y"
                             (click)="$event.stopImmediatePropagation()">
                <em-note-sticky
                  [text]="this.getRelationNoteText(relation)"
                  [width]="170"
                  [maxCollapsedHeight]="90"
                  [maintainMinimumHeight]="true"
                  [left]="0"
                  [noteType]="getRelationNoteType(relation)"
                  [maxExpandedHeight]="200"
                  (pointerenter)="$event.stopImmediatePropagation()"
                  (click)="selectConnection(relation.sourceId, relation.targetId,
                  this.calculateRelationCoordinates(relation.sourceId, relation.targetId).x,
                  this.calculateRelationCoordinates(relation.sourceId, relation.targetId).y,
                  relation.relationItemId, 'problem')"
                  style="bottom: 0">
                </em-note-sticky>
              </foreignObject>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let relation of getGameDesignRelations()">
            <ng-container *ngIf="hasRelationNote(relation)">
              <foreignObject class="relation-note-container" width="185" height="90" style="z-index:10"
                             [attr.x]="calculateRelationNoteCoordinate(relation).x"
                             [attr.y]="calculateRelationNoteCoordinate(relation).y"
                             (click)="$event.stopImmediatePropagation()">
                <em-note-sticky
                  [text]="this.getRelationNoteText(relation)"
                  [width]="170"
                  [maxCollapsedHeight]="90"
                  [maintainMinimumHeight]="true"
                  [left]="0"
                  [noteType]="getRelationNoteType(relation)"
                  [maxExpandedHeight]="200"
                  (pointerenter)="$event.stopImmediatePropagation()"
                  (click)="selectConnection(relation.sourceId, relation.targetId,
                  this.calculateRelationCoordinates(relation.sourceId, relation.targetId).x,
                  this.calculateRelationCoordinates(relation.sourceId, relation.targetId).y,
                  relation.relationItemId, 'pattern')"
                  style="bottom: 0">
                </em-note-sticky>
              </foreignObject>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isCardSelected && cardWeb.cards.length">
          <!--explicitly is false when cards[] is empty to remove all artifacts when editor is reset-->
          <a (click)="removeCard()">
            <svg class="deleteSymbol" width="22" height="22" viewBox="-2 -2 24 24" [attr.x]="plusSignX + 270"
                 [@fadeInOut] style="cursor: pointer"
                 [attr.y]="plusSignY - 10">
              <rect width="22" height="22" fill-opacity="0"/>
              <line x1="1" y1="2" x2="18" y2="18" stroke="#e62727" stroke-width="5"/>
              <line x1="1" y1="18" x2="18" y2="2" stroke="#e62727" stroke-width="5"/>
            </svg>
          </a>

          <a *ngIf="canConnectLeft()" (click)="openSideMenu('left')">
            <svg class="plus-sign-left" [attr.x]="plusSignX - 35" [attr.y]="plusSignY + 330"
                 style="cursor: pointer"
                 [@fadeInOut]>
              <circle [attr.cx]="20" [attr.cy]="20" r="18" fill="#4b4b4b" stroke="#4b4b4b" stroke-width="1" [@fadeInOut]
                      stroke-opacity="1" fill-opacity="0.9"/>
              <line [attr.x1]="8" [attr.y1]="20" [attr.x2]="32" [attr.y2]="20" stroke="white" stroke-width="4"
                    stroke-opacity="1"/>
              <line [attr.x1]="20" [attr.y1]="8" [attr.x2]="20" [attr.y2]="32" stroke="white" stroke-width="4"
                    stroke-opacity="1"/>
            </svg>
          </a>

          <a *ngIf="canConnectRight()" (click)="openSideMenu('right')">
            <svg class="plus-sign-right" [attr.x]="plusSignX + 240 +35" [attr.y]="plusSignY + 330" [@fadeInOut]
                 style="cursor: pointer">
              <circle [attr.cx]="20" [attr.cy]="20" r="18" fill="#4b4b4b" stroke="#4b4b4b" stroke-width="1" [@fadeInOut]
                      stroke-opacity="1" fill-opacity="0.9"/>
              <line [attr.x1]="8" [attr.y1]="20" [attr.x2]="32" [attr.y2]="20" stroke="white" stroke-width="4"
                    stroke-opacity="1"/>
              <line [attr.x1]="20" [attr.y1]="8" [attr.x2]="20" [attr.y2]="32" stroke="white" stroke-width="4"
                    stroke-opacity="1"/>
            </svg>
          </a>

          <a *ngIf="canConnectBottom()" (click)="openSideMenu('bottom')">
            <svg class="plus-sign-bottom" [attr.x]="plusSignX + 120" [attr.y]="plusSignY + 380 + 40"
                 style="cursor: pointer">
              <circle [attr.cx]="20" [attr.cy]="20" r="18" fill="#4b4b4b" stroke="#4b4b4b" stroke-width="1" [@fadeInOut]
                      stroke-opacity="1" fill-opacity="0.9"/>
              <line [attr.x1]="8" [attr.y1]="20" [attr.x2]="32" [attr.y2]="20" stroke="white" stroke-width="4"
                    stroke-opacity="1"/>
              <line [attr.x1]="20" [attr.y1]="8" [attr.x2]="20" [attr.y2]="32" stroke="white" stroke-width="4"
                    stroke-opacity="1"/>
            </svg>
          </a>

          <a (click)="openCardNodeMenu()">
            <svg xmlns="http://www.w3.org/2000/svg" [attr.x]="plusSignX + 277" [attr.y]="plusSignY + 240"
                 style="cursor: pointer" height="40px" width="40px" viewBox="0 -960 960 960" fill="#545454">
              <rect rx="100" y="-960" height="960px" width="960px" fill="#4b4b4b" [@fadeInOut]
                    stroke-opacity="1" fill-opacity="0.9"/>
              <path xmlns="http://www.w3.org/2000/svg" d="M200-200h360v-200h200v-360H200v560Zm0 80q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v400L600-120H200Zm80-280v-80h200v80H280Zm0-160v-80h400v80H280Zm-80 360v-560 560Z" fill="white"/>
            </svg>
          </a>
        </ng-container>
      </svg>

    </div>
  </ng-container>

</div>

