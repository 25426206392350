import {Injectable} from '@angular/core';
import {ConnectorMessageType} from './connector-message.type';
import {ConnectorMessage, CustomCardWeb} from './connector-message';
import {CardWeb} from './card-web';
import {GameDesign} from '../design-recommender/shared/game-design';
import {ObjectExtractingServiceService} from './object-extracting-service.service';
import {CardWebService} from './card-web.service';
import {GameDesignService} from '../design-recommender/shared/game-design.service';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BehaviorSubject, Observable} from 'rxjs';
import {CustomObjectsService} from './custom-objects.service';

// Change URL vor testing purpose of Java Backend wss://localhost:8080/design
const SOCKET_BASE_URL = '/design/';

@Injectable({
  providedIn: 'root'
})
export class CardWebConnectorService {
  private socket: WebSocket;
  private saveBasedOnAction = true;
  private userId: number;

  private noReconnects: any;
  private RECONNECT_INTERVAL: 5000;

  private statusSubject: BehaviorSubject<string> = new BehaviorSubject<string>('AutoSave nicht verbunden');
  public status$: Observable<string> = this.statusSubject.asObservable();

  constructor(private auth: OidcSecurityService,
              private oes: ObjectExtractingServiceService,
              private cws: CardWebService,
              private gs: GameDesignService,
              private cos: CustomObjectsService,
              private snackBar: MatSnackBar) {
    this.auth.userData$.subscribe(userdata => {
      if (userdata != null && userdata.userData != null) {
        this.userId = userdata.userData.sub;
        this.connect();
      }
    });
    this.oes.getMessageBehaviorSubject().subscribe(message => {
      this.handleAction(message);
    });
  }

  private connect() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      console.log('WebSocket is already open.');
      return; // WebSocket is already connected
    }

    if (this.socket && this.socket.readyState === WebSocket.CONNECTING) {
      console.log('WebSocket connection is in progress.');
      return; // WebSocket is in the process of connecting
    }

    if (this.socket) {
      this.socket.close(); // Close the existing socket if not already closed
    }

    try {
      console.log('::::::INITIALIZE WEBSOCKET:::::::');
      this.socket = new WebSocket(SOCKET_BASE_URL + this.userId);
    } catch (e) {
      console.log(':::::::::::.init errror');
      this.scheduleReconnect();
    }

    this.socket.onopen = () => {
      console.log('WebSocket connection opened.');

      this.statusSubject.next('AutoSave verbunden');

      if (this.noReconnects) {
        this.noReconnects = true;
        this.snackBar.open('Verbindung wieder hergestellt', '',
          {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 3000
          });
      }
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);

      this.statusSubject.next('AutoSave nicht verbunden');

      this.noReconnects = false;
      this.scheduleReconnect();
    };

    // unexpected websocket close
    this.socket.onclose = (event) => {
      console.log('WebSocket connection closed:', event);

      this.statusSubject.next('AutoSave nicht verbunden');

      this.noReconnects = false;
      this.scheduleReconnect();
    };

    this.socket.onmessage = (event: MessageEvent) => {

      const data: ConnectorMessage = JSON.parse(event.data);
      if (data.type === 'SAVE_MESSAGE') {
        console.log(':::::::::::::::::::::::::.' + data.cardWeb);
        this.cws.loadCardWebByServer(data.cardWeb);
        this.gs.loadGameDesignByServer(data.gameDesign);
        this.cos.loadCustomObjectsByServer(data.customObjects);
      }
    };
  }

  private scheduleReconnect() {
    if (!this.noReconnects) {
      if (this.socket.readyState === WebSocket.OPEN) {
        return;
      }
      setTimeout(() => {
        console.log('Attempting to reconnect WebSocket...');

        this.statusSubject.next('AutoSave Verbindungsaufbau');


        this.connect();
      }, this.RECONNECT_INTERVAL);
    }
  }

  private saveCurrentValues(cardWeb: CardWeb, gameDesign: GameDesign) {
    if (this.socket.readyState === WebSocket.OPEN) {
      console.log('saving...');
      const message: CustomCardWeb = {
        type: ConnectorMessageType.SAVE_MESSAGE,
        cardWeb,
        gameDesign
      };
      console.log(message);
      this.socket.send(JSON.stringify(message));
      console.log('saved successfully');
    } else {
      console.log('Socket is not open');
    }
  }

  private sendMessage(message: ConnectorMessage) {
    if (this.socket.readyState === WebSocket.OPEN) {
      console.log('saving...');
      this.socket.send(JSON.stringify(message));
      console.log('saved successfully');
    }
  }

  public handleAction(message: ConnectorMessage) {
    if (message == null) {
      return;
    }
    console.log(message);
    message.customObjects = this.cos.getCustomObjects();
    message.cardWeb = this.cws.getCustomCardWeb();
    message.gameDesign = this.gs.getGameDesign();
    this.sendMessage(message);
    return;
  }
}
