import {Component, Inject, OnInit} from '@angular/core';
import {CardStoreService} from '../shared/card-store.service';
import {UserValidation} from '../shared/user-validation';
import {Observable} from 'rxjs';

@Component({
  selector: 'em-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
/**
 * Shows the landing page with the login screen.
 *
 * Its observalbe userValidation$ is initialized using
 * the UserLoginListener from the CardStoreService.
 * The userValidation$ has the user data shown after a
 * successful login (e.g. organization, unlocked carddecks)
 */
export class HomeComponent implements OnInit {
  constructor(private cs: CardStoreService) { }
  userValidation$: Observable<UserValidation>;

  ngOnInit(): void {
    this.cs.getUserLoginListener().subscribe( b => this.userValidation$ = b);
  }

}
