<mat-sidenav-container>
  <mat-sidenav #secondRightSidenav mode="over" [fixedInViewport]="true" [fixedTopGap]=1 position="end">
    <div class="toolbarspacer"></div>
    <div class="closeMenu">
      <mat-icon (click)="secondRightSidenav.toggle()">arrow_back</mat-icon>
    </div>
    <em-side-nav-content [name]="'patternConnection'"></em-side-nav-content>
  </mat-sidenav>

  <mat-sidenav-content>
    <ng-container *ngIf="cardPattern">
      <div class="card_discussion_frontside">
        <div class="card_discussion_header">
          <table class="card_discussion_header">
            <tr>
              <td class="card_discussion_header_number">{{ cardPattern.cardNumber }}</td>
              <td class="card_discussion_header_title">{{ cardPattern.name }}</td>
              <td class="card_discussion_header_image">
                <img [src]="cardPattern.imageUrl" alt="Icon">
              </td>
            </tr>
          </table>
        </div>
        <div class="card_discussion_summary">
          {{ cardPattern.summary }}
        </div>
      </div>
      <ng-container class="customConnectListItem" *ngIf="sourceIsNotInCardWeb">
        <div style="color: red; font-weight: bold; font-size: 16px; padding: 10px; word-wrap: break-word;">
          '{{cardPattern.name}}' befindet sich nicht
          im Editor. Bitte ziehen Sie die Karte erst in den Editor.
        </div>
      </ng-container>
      <div class="sidenav_container" [@onSideNavChangeRight]="sideNavStateRight ? 'close' : 'close'">
        <div class="patternListSmall" fxLayout="column" fxLayoutGap="10px" style="height: 100%;">
          <!--ng-container *ngIf="this.cardPattern.cardDeckType == 'pattern' "-->
            <!-- show all patterns that are not in cardcells to allow custom connections -->
            <ng-container *ngFor="let card of this.cardNeighbours">
              <ng-container *ngIf="this.patternNotInCardCells(card)">
                <em-card-pattern-small [cardDeckId]="this.cardPattern.cardDeckId"
                                       [cardDeckType]="this.cardPattern.cardDeckType"
                                       [cardPatternId]="card"
                                       [customConnection]="true"
                                       [sourceDeckType]="this.cardPattern.cardDeckType"
                                       [sourcePatternId]="this.cardPattern.id"
                                       [sourcePatternName]="this.cardPattern.name"></em-card-pattern-small>
              </ng-container>
            </ng-container>
            <br/>
            <!--div class="customConnectionListTitle"
                 (click)="customConnectionMenu.toggle();
                  this.es.setConnectionParams(cardPattern)"
                 [routerLink]="[{outlets:{customConnections:['custom-connections']}}]">
              <mat-icon style="margin-left: auto; vertical-align: middle">chevron_right</mat-icon>
              Eigene Verbindung festlegen
            </div-->

          <!--/ng-container-->

          <!-- show connections that are already in cardcells and their state of recommendation -->
          <ng-container
            *ngFor="let cc of cardPattern.cardCells | connectionsSort: this.gameDesign: this.recommendedGameDesign: this.cardPattern.id: this.cardPattern.cardDeckType">
            <ng-container *ngIf="cc.cardNumber !== 0">
              <a (click)="secondRightSidenav.toggle()"
                 [routerLink]="[{outlets:{second:['pattern-connection']}}]"
                 [queryParams]="{
              carddeckid:cardPattern.cardDeckId,
              carddecktype:cardPattern.cardDeckType,
              sourceid:cc.sourceId,
              targetid:cc.targetId,
              obstacledeckid: cc.cardDeckId}">
                <div emRecommendedRelation
                     [sourceId]="cc.sourceId" [targetId]="cc.targetId" [cardDeckType]="cardPattern.cardDeckType">
                  <em-card-pattern-small [cardDeckId]="cc.cardDeckId" [cardDeckType]="cardPattern.cardDeckType"
                                         [cardPatternId]="cc.targetId"
                                         [customConnection]="false"></em-card-pattern-small>
                </div>
              </a>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </mat-sidenav-content>
</mat-sidenav-container>
<mat-sidenav-container [hasBackdrop]="true">
  <mat-sidenav mode="over" #customConnectionMenu [fixedInViewport]="true" [fixedTopGap]=1 position="end">
    <div class="toolbarspacer"></div>
    <div class="closeMenu">
      <mat-icon (click)="customConnectionMenu.toggle()">arrow_back</mat-icon>
    </div>
    <em-side-nav-content [name]="'customConnections'"></em-side-nav-content>
  </mat-sidenav>
</mat-sidenav-container>
