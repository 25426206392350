import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CardStoreService} from './card-store.service';
import {CardNote, CardNoteType, CustomObjects, NoteType, RelationNote} from './custom-objects';
import {ObjectExtractingServiceService} from './object-extracting-service.service';
import {ConnectorMessageType} from './connector-message.type';

@Injectable({
  providedIn: 'root'
})
export class CustomObjectsService {

  public customObjects: CustomObjects;
  public co$: BehaviorSubject<CustomObjects>;

  constructor(private cs: CardStoreService,
              private ccs: ObjectExtractingServiceService) {
    this.cs.getUserLoginListener().subscribe(b => {
      if (b === undefined) {
        this.customObjects = undefined;
      }
    });
    this.initCustomObjects();
  }

  public initCustomObjects() {
    this.customObjects = {
      id: 1,
      cardNotes: [],
      relationNotes: []
    };
    this.co$ = new BehaviorSubject<CustomObjects>(this.customObjects);
  }

  loadCustomObjectsByServer(customObjects: CustomObjects) {
    console.log('::LOAD CUSTOMOBJECTS');

    if (!customObjects) {
      console.warn('Received null or undefined customObjects');
      return;
    }

    this.customObjects = {
      id: customObjects.id ?? 1,  // Fallback to a default ID if undefined
      cardNotes: customObjects.cardNotes ?? [],  // Ensure it's always an array
      relationNotes: customObjects.relationNotes ?? []  // Ensure it's always an array
    };

    if (this.co$) {
      this.co$.next(this.customObjects);
    } else {
      this.co$ = new BehaviorSubject<CustomObjects>(this.customObjects);
    }
  }

  public getCustomObjects() {
    if (this.customObjects === undefined) {
      throw new Error('No CustomObjects initialized');
    }
    return this.customObjects;
  }

  public getCustomObjectsBehaviorSubject(): BehaviorSubject<CustomObjects> {
    if (this.co$ === undefined) {
      this.co$ = new BehaviorSubject<CustomObjects>(this.customObjects);
    }
    return this.co$;
  }


  // CARD NOTE FUNCTIONALITY
  public addOrUpdateCardNote(noteType: CardNoteType, text: string, sourceId: number) {
    if (this.hasCardNote(sourceId)) {
      const existingNote: CardNote = this.getCardNote(sourceId);
      existingNote.type = noteType;
      existingNote.text = text;
    } else {
      const note: CardNote = {
        type: noteType,
        text,
        sourceId
      };
      this.customObjects.cardNotes.push(note);
    }
    this.co$.next(this.customObjects);

    // CALL WEBSOCKET CONNECTION
    this.ccs.handleAction({
      type: ConnectorMessageType.ADD_CUSTOM_OBJECT,
      noteType,
      text,
      sourceId
    });
  }

  public removeCardNote(sourceId: number) {
    this.customObjects.cardNotes = this.customObjects.cardNotes.filter(note => note.sourceId !== sourceId);
    this.co$.next(this.customObjects);

    // CALL WEBSOCKET CONNECTION
    this.ccs.handleAction({
      type: ConnectorMessageType.DELETE_CUSTOM_OBJECT,
      sourceId
    });
  }

  public getCardNote(sourceId: number): CardNote {
    return this.customObjects.cardNotes.find((note) => note.sourceId === sourceId);
  }


  // RELATION NOTE FUNCTIONALITY
  public addOrUpdateRelationNote(text: string, sourceId: number, targetId: number) {
    if (this.hasRelationNote(sourceId, targetId)) {
      const existingNote: RelationNote = this.customObjects.relationNotes.find(
        (rn) => rn.sourceId === sourceId && rn.targetId === targetId);
      existingNote.text = text;
    } else {
      const note: RelationNote = {
        type: NoteType.RELATION_ELEMENT,
        text,
        sourceId,
        targetId
      };
      this.customObjects.relationNotes.push(note);
    }
    this.co$.next(this.customObjects);

    // CALL WEBSOCKET CONNECTION
    this.ccs.handleAction({
      type: ConnectorMessageType.ADD_CUSTOM_OBJECT,
      text,
      sourceId,
      targetId
    });
  }

  public removeRelationNote(sourceId: number, targetId: number) {
    const index = this.customObjects.relationNotes.findIndex(note => (note.sourceId === sourceId && note.targetId === targetId));
    if (index > -1) {
      this.customObjects.relationNotes.splice(index, 1);
    }
    this.co$.next(this.customObjects);

    // CALL WEBSOCKET CONNECTION
    this.ccs.handleAction({
      type: ConnectorMessageType.DELETE_CUSTOM_OBJECT,
      sourceId,
      targetId
    });
  }

  public getRelationNote(sourceId: number, targetId: number): RelationNote {
    return this.customObjects.relationNotes.find(rn => rn.sourceId === sourceId && rn.targetId === targetId);
  }

  hasCardNote(selectedCardId: number) {
    if (this.customObjects) {
      return this.customObjects.cardNotes.some(rn => rn.sourceId === selectedCardId);
    } else {
      return false;
    }
  }

  hasRelationNote(sourceId: number, targetId: number) {
    if (this.customObjects){
    return this.customObjects.relationNotes
      .some(rn => rn.sourceId === sourceId && rn.targetId === targetId);
  }else {
      return false;
    }
  }

  clearCustomObjects() {
    this.customObjects.cardNotes.splice(0, this.customObjects.cardNotes.length);
    this.customObjects.relationNotes.splice(0, this.customObjects.relationNotes.length);
  }
}
