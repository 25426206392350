<div class="sticky-note-wrapper" (mouseover)="showButton = true" (mouseleave)="showButton = false"
     [ngClass]="{'expanded': expanded, 'sticky-note': this.hasNote(), 'missing-note': !this.hasNote()}"
     [ngStyle]="{
        'background-color': getBackgroundColor(),
        'width.px': hasNote() ? width : width + 20,
        'height.px': expanded ? getExpandedHeight() : this.hasNote() && this.maintainMinimumHeight ? this.maxCollapsedHeight : getHeight(),
        'left.rem': left,
        'z-index': expanded ? 1000 : 10
      }">

  <!-- Display Content (Editable only in edit mode) -->
  <div class="content" *ngIf="!expanded"
       [ngStyle]="{
        'height.px': this.hasNote() ? this.maxCollapsedHeight-12 : getHeight(),
        'text-align': this.hasNote() ? 'left' : 'justify'
       }">{{this.getText()}}
  </div>
  <div class="content content-expanded" *ngIf="expanded"
      [ngStyle]="{
        'height.px': getExpandedHeight()
      }">{{this.getText()}}
  </div>
  <!-- Edit, Save, Cancel, Expand Buttons -->
  <button *ngIf="!expanded && this.hasOverflowText() && this.hasNote() && showButton" class="expand-button" (click)="toggleExpand()">mehr</button>
</div>
