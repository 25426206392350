<div class="dialog" mat-dialog-content>
  <div *ngIf="sourceCardPattern$ | async as cardPattern; else loading" class="dialog-containers editorElement">
    <ng-container *ngIf="targetCardPattern$ | async as targetPattern; else loading">
    <!-- Card Content -->
      <div class="card-content-container">
        <div class="card-content" (click)="$event.stopImmediatePropagation()">
          <em-pattern-card
                  [cardPatternId]="cardPattern.id"
                  [cardDeckId]="cardPattern.cardDeckId"
                  [cardDeckType]="cardPattern.cardDeckType">
          </em-pattern-card>
          <ng-container *ngIf="hasCardNote(cardPattern)">
            <em-note-sticky
                    [text]="this.getCardNoteText(cardPattern)"
                    [width]="185"
                    [maxCollapsedHeight]="135"
                    [maintainMinimumHeight]="true"
                    [left]="0"
                    [noteType]="this.getCardNoteType(cardPattern)"
                    [maxExpandedHeight]="230"
                    (pointerenter)="$event.stopImmediatePropagation()"
                    style="position:absolute; bottom: 2rem;"
            >
            </em-note-sticky>
          </ng-container>
        </div>
        <div class="card-content">
          <!--div class="color-buttons">
            <section>
              <mat-button-toggle-group
                      name="favoriteColor"
              >
                <mat-button-toggle  value="part-of" (change)="toggleChange($event)">Teil-Ganzes</mat-button-toggle>
                <mat-button-toggle value="if-then">Wenn-Dann</mat-button-toggle>
              </mat-button-toggle-group>
            </section>
          </div-->
          <svg xmlns="http://www.w3.org/2000/svg" height="110px" viewBox="0 -960 960 960" width="180px" fill="#cccccc">
            <!--path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z"/-->
            <path xmlns="http://www.w3.org/2000/svg" d="M400-280v-400l200 200-200 200Z"/>
          </svg>
          <div class="note" [ngStyle]="{ backgroundColor: selectedColor }">
            <!-- Note Input Section -->
            <div class="note-input">
            <textarea class="note-textarea" style="resize: none" [(ngModel)]="this.note.text"
                      placeholder="Schreiben Sie hier..." maxlength="300" emAutofocus></textarea>
            </div>
          </div>
        </div>
        <div class="card-content"  (click)="$event.stopImmediatePropagation()">
          <em-pattern-card
                  [cardPatternId]="targetPattern.id"
                  [cardDeckId]="targetPattern.cardDeckId"
                  [cardDeckType]="targetPattern.cardDeckType">
          </em-pattern-card>
          <ng-container *ngIf="hasCardNote(targetPattern)">
            <em-note-sticky
                    [text]="this.getCardNoteText(targetPattern)"
                    [width]="185"
                    [maxCollapsedHeight]="135"
                    [maintainMinimumHeight]="true"
                    [left]="0"
                    [noteType]="this.getCardNoteType(targetPattern)"
                    [maxExpandedHeight]="230"
                    (pointerenter)="$event.stopImmediatePropagation()"
                    style="position:absolute; bottom: 2rem;"
            >
            </em-note-sticky>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<br>
<mat-divider></mat-divider>
<div mat-dialog-actions class="actions">
  <button *ngIf="this.hasRelationNote()" type="button" mat-button mat-dialog-close color="accent" (click)="removeRelationNote()">Notiz löschen</button>
  <button type="button" mat-button color="primary" mat-dialog-close>Schließen</button>
  <button  type="submit"  mat-button  color="primary"  (click)="saveNote()"  mat-dialog-close>  {{ this.hasRelationNote() ? 'Notiz ändern' : 'Notiz anlegen' }}</button>
</div>

<ng-template #loading>
  <!-- Skeleton Loading -->
  <div class="skeleton-container">
    <div class="skeleton skeleton-title"></div>
    <div class="skeleton skeleton-image"></div>
    <div class="skeleton skeleton-text"></div>
    <div class="skeleton skeleton-input"></div>
  </div>
</ng-template>

