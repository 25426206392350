import {Component, ElementRef, HostListener, Input} from '@angular/core';
import {NoteType} from '../../shared/custom-objects';
import {ColorFactory} from '../../shared/color-factory';

@Component({
  selector: 'em-note-sticky',
  templateUrl: './note-sticky.component.html',
  styleUrls: ['./note-sticky.component.css']
})
export class NoteStickyComponent {
  @Input() text = '';
  @Input() width = 200;
  @Input() maintainMinimumHeight = true;
  @Input() maxCollapsedHeight = 135;
  @Input() left = 2;
  @Input() maxExpandedHeight = 300;
  @Input() noteType: NoteType = NoteType.EXISTING_ELEMENT;

  expanded = false;
  showButton = false;

  constructor(private elRef: ElementRef) {
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }


  getExpandedHeight() {
    const calculatedHeight = this.getCalculatedHeight();
    return calculatedHeight > this.maxExpandedHeight  ? this.maxExpandedHeight : calculatedHeight;
  }

  getHeight() {
    const calculatedHeight = this.getCalculatedHeight();
    return calculatedHeight > this.maxCollapsedHeight ? this.maxCollapsedHeight : calculatedHeight;
  }

  private getCalculatedHeight(){
    const manualBreakLines = this.countManualBreakLines(this.text);
    const textLength = 20 > this.text.length ? 20 : this.text.length;
    let maxCharPerLine = 27;
    if (this.noteType === NoteType.RELATION_ELEMENT){
      maxCharPerLine = 28;
    }
    return 19 * (manualBreakLines + (textLength / maxCharPerLine));
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    if (this.expanded && !this.elRef.nativeElement.contains(event.target)) {
      this.expanded = false;
    }
  }



  getBackgroundColor() {
    return ColorFactory.getColor(this.noteType);
  }

  getText() {
    return this.text.trim();
  }

  hasOverflowText() {
    const calculatedHeight = this.getCalculatedHeight();
    return calculatedHeight > this.maxCollapsedHeight;
  }

  hasNote() {
   return this.noteType !== NoteType.MISSING_NOTE;
  }

  private countManualBreakLines(text: string) {
    return (text.match(/(^|[^\n])\n(?!\n)/g) || []).length;
  }
}
