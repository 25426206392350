import {ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {CardDeck, DeckType} from '../../shared/card-deck';
import {MatSidenav} from '@angular/material/sidenav';
import {RecommendedGameDesign} from '../../design-recommender/shared/recommended-game-design';
import {GameDesign} from '../../design-recommender/shared/game-design';
import {UserValidation} from '../../shared/user-validation';
import {AttributionType} from '../../deck-explorer/shared/attribution-types.enum';
import {CardStoreService} from '../../shared/card-store.service';
import {RecommendationService} from '../../design-recommender/shared/recommendation.service';
import {GameDesignService} from '../../design-recommender/shared/game-design.service';
import {AttributionService} from '../../deck-explorer/shared/attribution.service';
import {CardWebService} from '../../shared/card-web.service';
import {CardWeb} from '../../shared/card-web';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CardWebElement} from '../../shared/card-web-element';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CustomObjectsService} from '../../shared/custom-objects.service';
import {MatDialog} from '@angular/material/dialog';
import {NoteDialogElementComponent} from '../note-dialog-element/note-dialog-element.component';
import {CustomObjects, NoteType} from '../../shared/custom-objects';
import {NoteDialogRelationComponent} from '../note-dialog-relation/note-dialog-relation.component';
import {GameDesignRelation} from '../../design-recommender/shared/game-design-relation';

@Component({
  selector: 'em-svg-viewer',
  templateUrl: './svg-viewer.component.html',
  styleUrls: ['./svg-viewer.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({opacity: 0})), // Initial state
      transition('void <=> *', [ // Transition between void (hidden) and any state (visible)
        animate('300ms ease-in-out'), // Animation duration and easing
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgViewerComponent implements OnInit {

  constructor(
    private cs: CardStoreService,
    private rs: RecommendationService,
    private gs: GameDesignService,
    private cos: CustomObjectsService,
    private as: AttributionService,
    private cws: CardWebService,
    private snackBar: MatSnackBar,
    private noteDialog: MatDialog
  ) {
    this.cs.getUserLoginListener().subscribe(b => {
      if (b === undefined) {
        this.recommendedGameDesign = undefined;
        this.gameDesign = undefined;
        this.cardDeck$ = undefined;
        this.userValidation$ = undefined;
        this.cardWeb = undefined;
        this.customObjects = undefined;
      }
    });
  }
  @ViewChild('svgContainer') svgContainer!: ElementRef<SVGSVGElement>;
  gridDragStartX = 0;
  gridDragStartY = 0;
  cardDragStartX = 0;
  cardDragStartY = 0;

  pointerEventClickX = 0;
  pointerEventClickY = 0;

  viewBoxX = 10000;
  viewBoxY = 6000;
  viewBoxWidth = 800;
  viewBoxHeight = 800;
  zoomFactor = 1;

  cardDrag = false;
  gridDrag = false;
  draggedCard: any = null;
  selectedWebElement: CardWebElement = null;

  showHoverCard = false;
  hoveredGridX = 0;
  hoveredGridY = 0;

  plusSignX = 100;
  plusSignY = 100;

  isCardSelected = false;
  selectedCardId = 0;
  selectedCardDeckId = 0;
  selectedCardDeckType: DeckType = null;

  isConnectionSelected = false;
  selectedConnection = {sourceId: null, targetId: null, posX: null, posY: null, relationItemId: null, obstaclePatternType: null};

  cardDeck$: Observable<CardDeck>;

  cardWeb: CardWeb;
  customObjects: CustomObjects;

  @Input() cardDeckIdSelection: number;
  @Input() rightSidenav: MatSidenav;
  recommendedGameDesign: RecommendedGameDesign;
  gameDesign: GameDesign;

  userValidation$: Observable<UserValidation>;

  attribution: AttributionType;
  subAttribution: AttributionType;
  recommendationSorting: boolean;

  protected readonly NoteType = NoteType;

  ngOnInit(): void {

    this.as.getAttributionBehaviorSubject().subscribe(
      attributionType => {
        this.attribution = attributionType;
      }
    );
    this.as.getSubAttributionBehaviorSubject().subscribe(
      attributionType => {
        this.subAttribution = attributionType;
      }
    );
    this.cs.getUserLoginListener().subscribe(b => {
      this.userValidation$ = b;
    });
    // Ensure that the GameDesign is initizalized with ALL cardDecks
    // and not only with the one derived from the routing param
    this.userValidation$.subscribe(userValidation => {
      console.log('::::::::::.VALIDATION');
      for (const cardDeck of userValidation.carddecks) {
        this.gs.initGameDesign(cardDeck.id, cardDeck.type);

        this.gameDesign = this.gs.getGameDesign();
        this.gs.getGameDesignBehaviorSubject().subscribe(gameDesign => {
          this.gameDesign = gameDesign;
          // TODO: CallStack Error
        });

        // Changes in the game design should cause new recommendations
        if (!this.rs.checkIfRecommendationRequestIsPossible()) {
          this.rs.setGameDesignBehaviorSubject(this.gs.getGameDesignBehaviorSubject());
        }

        // Changes in the recommendation should change the recommended game design
        this.rs.getRecommendationBehaviorSubject().subscribe(recommendedGameDesign => {
          if (this.rs.hasRecommendation()) {
            this.recommendedGameDesign = recommendedGameDesign;
          } else {
            this.recommendedGameDesign = null;
          }
        });

        this.rs.getRecommendationSortingBehaviorSubject().subscribe(sort => {
          this.recommendationSorting = sort;
        });

      }
    });
    this.cws.loadCustomCardWeb(1);
    this.cws.getCustomCardWebBehaviorSubject().subscribe(cardWeb => {
      this.cardWeb = this.cws.getCustomCardWeb();
    });
    this.cos.getCustomObjectsBehaviorSubject().subscribe(customObject => {
      this.customObjects = customObject;
    });
  }


  startDragGrid(event: any) {
    if (!this.cardDrag) {
      this.gridDrag = true;
      event.preventDefault(); // stop event from reaching anything else than the grid
      event.stopPropagation();
      this.gridDragStartX = event.clientX;
      this.gridDragStartY = event.clientY;
    }
  }

  doDragGrid(event: any) {
    if (!this.cardDrag && this.gridDrag) {
      event.preventDefault();
      event.stopPropagation();
      if (event.buttons !== 1) {
        return; // only drag if left mouse button is clicked
      }

      const dragDistanceX = event.clientX - this.gridDragStartX;
      const dragDistanceY = event.clientY - this.gridDragStartY;

      this.viewBoxX -= dragDistanceX * this.zoomFactor;
      this.viewBoxY -= dragDistanceY * this.zoomFactor;

      this.gridDragStartX = event.clientX;
      this.gridDragStartY = event.clientY;

      this.updateViewBox();
    }
  }


  zoom(event: any) {
    event.preventDefault();
    event.stopPropagation();
    const zoomDelta = Math.sign(event.deltaY) * 0.1;

    const oldZoomFactor = this.zoomFactor;
    this.zoomFactor += zoomDelta;
    this.zoomFactor = Math.max(0.1, this.zoomFactor);

    const zoomCenterX = this.viewBoxX;
    const zoomCenterY = this.viewBoxY;

    const newViewBoxWidth = this.viewBoxWidth / oldZoomFactor * this.zoomFactor;
    const newViewBoxHeight = this.viewBoxHeight / oldZoomFactor * this.zoomFactor;

    this.viewBoxX = zoomCenterX - (zoomCenterX - this.viewBoxX) / oldZoomFactor * this.zoomFactor;
    this.viewBoxY = zoomCenterY - (zoomCenterY - this.viewBoxY) / oldZoomFactor * this.zoomFactor;

    this.viewBoxWidth = newViewBoxWidth;
    this.viewBoxHeight = newViewBoxHeight;

    this.updateViewBox();
  }

  /**
   * svg view will only be updated if attributes change.
   * After one grid drag calculation cycle is done attributes will be updated
   */
  updateViewBox() {
    const svg = this.svgContainer.nativeElement;
    const viewBox = `${this.viewBoxX} ${this.viewBoxY} ${this.viewBoxWidth} ${this.viewBoxHeight}`;
    svg.setAttribute('viewBox', viewBox);
  }

  // *** card and connections logic - drag, select, buttons ***

  selectCard(card: any, webElement: CardWebElement) {
    this.selectedCardId = +webElement.id;
    this.selectedCardDeckId = +webElement.cardDeckId;
    this.selectedCardDeckType = webElement.cardDeckType;
    this.selectedWebElement = webElement;
    this.isCardSelected = true;
    this.plusSignX = +card.getAttribute('x');
    this.plusSignY = +card.getAttribute('y');
    this.deselectAllConnections();
  }

  public deselectAllCards() {
    if (this.isCardSelected) {
      this.selectedWebElement = null;
      console.log('deselect');
      this.selectedCardId = 0;
      this.isCardSelected = false;
    }
  }

  public deselectAllConnections() {
    this.isConnectionSelected = false;
    this.selectedConnection.sourceId = null;
    this.selectedConnection.targetId = null;
    this.selectedConnection.posX = null;
    this.selectedConnection.posY = null;
  }

  public selectConnection(
    sourceId: number,
    targetId: number,
    posX: number,
    posY: number,
    relationItemId: number,
    obstaclePatternType: string) {
    this.isConnectionSelected = true;
    this.selectedConnection.sourceId = sourceId;
    this.selectedConnection.targetId = targetId;
    this.selectedConnection.posX = posX;
    this.selectedConnection.posY = posY;
    this.selectedConnection.relationItemId = relationItemId;

    // DeckType can not be called in the html, therefore a string is passed
    if (obstaclePatternType === 'pattern') {
      this.selectedConnection.obstaclePatternType = DeckType.pattern;
    } else if (obstaclePatternType === 'problem') {
      this.selectedConnection.obstaclePatternType = DeckType.problem;
    }
    this.deselectAllCards();
    console.log('connectionSelected');
  }

  // card drag
  startDragElement(event: any, card: any, element: CardWebElement) {
      event.stopPropagation();
      event.preventDefault();
      this.gridDrag = false;
      this.cardDrag = true;
      this.draggedCard = card;
      this.pointerEventClickX = event.clientX;
      this.pointerEventClickY = event.clientY;
      this.cardDragStartX = Number(this.draggedCard.getAttribute('x'));
      this.cardDragStartY = Number(this.draggedCard.getAttribute('y'));
      this.hoveredGridX = this.cardDragStartX;
      this.hoveredGridY = this.cardDragStartY;
      this.showHoverCard = true;
      this.cardWeb.cards = this.cardWeb.cards.filter(obj => obj !== element);
      this.cardWeb.cards.push(element);
  }

  @HostListener('document:pointerup' || 'document:touchend', ['$event'])
  public handleUp(event: any) {
    if (this.cardDrag || this.gridDrag) {
      this.gridDrag = false;
      this.cardDrag = false;

      if (this.draggedCard) {
        if (!this.cws.checkCardCollision(this.hoveredGridX, this.hoveredGridY)) {
          this.draggedCard.setAttribute('x', this.hoveredGridX);
          this.draggedCard.setAttribute('y', this.hoveredGridY);

          this.cws.changeCardLocationInLocationGrid(
            +this.draggedCard.id, this.cardDragStartX,
            this.cardDragStartY, this.hoveredGridX,
            this.hoveredGridY);
        } else {
          this.draggedCard.setAttribute('x', this.cardDragStartX);
          this.draggedCard.setAttribute('y', this.cardDragStartY);
        }
        this.draggedCard = null;
        this.showHoverCard = false;
      }
    }
  }

  private detectSufficientDragDistance(event: any) {
    let sufficientDragDistance = false;
    sufficientDragDistance = Math.abs(event.clientX - this.pointerEventClickX) > 5;
    sufficientDragDistance = sufficientDragDistance || (Math.abs(event.clientY - this.pointerEventClickY) > 5);
    return sufficientDragDistance;
  }

  @HostListener('document:pointermove', ['$event'])
  public handleMove(event: any) {
    event.stopPropagation();
    if (!this.gridDrag && this.cardDrag && this.detectSufficientDragDistance(event)) {
      if (event.buttons !== 1) {
        return;
      }

      this.deselectAllCards();
      const svg = this.svgContainer.nativeElement;
      const point = svg.createSVGPoint();
      point.x = event.clientX;
      point.y = event.clientY;
      const cursorPt = point.matrixTransform(svg.getScreenCTM().inverse());
      this.draggedCard.setAttribute('x', cursorPt.x - 150);
      this.draggedCard.setAttribute('y', cursorPt.y - 200);
      this.hoveredGridX = Math.round((cursorPt.x - 150) / 400) * 400;
      const initialY = (Math.round((cursorPt.y - 150) / 500) * 500);
      if (this.hoveredGridX % 800 === 0) {
        this.hoveredGridY = initialY;
      } else {
        this.hoveredGridY = initialY - 250;
      }
    }
  }

  /**
   * removes the selected element from the editor
   */
  @HostListener('document:keydown.delete', ['$event'])
  @HostListener('document:keydown.backspace', ['$event'])
  onDeleteKey(event: KeyboardEvent) {
    if (this.isCardSelected) {
      this.removeCard();
    }
    if (this.isConnectionSelected) {
      this.removeConnection();
    }
  }

  public removeCard() {
    console.log(this.cardWeb);
    this.cws.removeCardWebElement(this.selectedCardId);
    this.deselectAllConnections();
    this.deselectAllCards();
  }

  public removeConnection() {
    this.gs.removeGameDesignRelationItem(this.selectedConnection.sourceId, this.selectedConnection.targetId,
      this.selectedConnection.relationItemId, this.selectedConnection.obstaclePatternType);
    // connections might be removed automatically by changing cards
    // indicate to user that a connection has been removed
    this.snackBar.open(' Verbindung entfernt', '',
      {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 3000
      });
    this.deselectAllConnections();
    this.deselectAllCards();
  }

  public calculateCardXCoordinates(gridPosX: number): number {
    return gridPosX * 400;
  }

  public calculateCardYCoordinates(gridPosY: number, gridPosX: number) {
    if ((gridPosX * 400) % 800 === 0) {
      return (gridPosY / 2) * 500;
    } else {
      return (((gridPosY - 1) / 2) * 500) + 250;
    }
  }

  public calculateRelationCoordinates(sourceId, targetId) {
    const sourceCard = this.cardWeb.cards.find(card => card.id === sourceId);
    const targetCard = this.cardWeb.cards.find(card => card.id === targetId);
    let x = 0;
    let y = 0;
    let pos = 'default';
    if (sourceCard.posX < targetCard.posX && sourceCard.posY < targetCard.posY) {
      x = this.calculateCardXCoordinates(sourceCard.posX) + 269;
      y = this.calculateCardYCoordinates(sourceCard.posY, sourceCard.posX) + 335;
      pos = 'right';
      return {x, y, pos};
    } else if (sourceCard.posX > targetCard.posX && sourceCard.posY < targetCard.posY) {
      x = this.calculateCardXCoordinates(sourceCard.posX) - 130;
      y = this.calculateCardYCoordinates(sourceCard.posY, sourceCard.posX) + 335;
      pos = 'left';
      return {x, y, pos};
    } else if (sourceCard.posX === targetCard.posX && sourceCard.posY < targetCard.posY) {
      x = this.calculateCardXCoordinates(sourceCard.posX) + 118;
      y = this.calculateCardYCoordinates(sourceCard.posY, sourceCard.posX) + 415;
      pos = 'bottom';
      return {x, y, pos};
    }
    return {x, y, pos};
  }

  public slicememe() {
    this.cws.addCardWebElement(112052, 'name', 234754, DeckType.pattern, 3, 3);
    this.cws.loadCustomWebGameDesign();
  }

  public dropNewCard(event: CdkDragDrop<any>): void {
    this.deselectAllCards();
    this.deselectAllConnections();
    const svg = this.svgContainer.nativeElement;
    const point = svg.createSVGPoint();
    point.x = event.dropPoint.x;
    point.y = event.dropPoint.y;

    const cursorPt = point.matrixTransform(svg.getScreenCTM().inverse());
    if (!this.cws.checkCardOutOfBounds(cursorPt.x, cursorPt.y)) {
      this.cws.addDroppedCard(event.item.data.cp.id, event.item.data.cp.name, event.item.data.cp.cardDeckId, event.item.data.cardDeck.type,
        cursorPt.x, cursorPt.y);
      console.log('drop');
      console.log(event.item.data.cp.id, event.item.data.cp.cardDeckId, event.item.data.cardDeck.type,
        cursorPt.x, cursorPt.y);
    }
  }

  /**
   * selects a card's primary connection buttons and forwards the click action
   * by that no extra routerlink and no extra data has to be requested
   */
  public openSideMenu(preference: ('left' | 'right' | 'bottom')) {
    const foreignObjectElement = this.svgContainer.nativeElement.getElementById(`${this.selectedCardId}`);
    if (foreignObjectElement) {
      const buttonElement = foreignObjectElement.querySelector('#sideMenuButton');
      if (buttonElement instanceof HTMLTableCellElement) {
        buttonElement.click(); // Simulate a click on the button.
      }
    }
    this.cws.setConnectionPreference(preference);
  }


  openCardNodeMenu() {
    this.noteDialog.open(NoteDialogElementComponent,
      {
          data: {
          sourceCardDeckId: this.cardDeckIdSelection,
          sourceCardPatternId: this.selectedCardId,
          sourceCardDeckType: this.selectedCardDeckType
        },
        autoFocus: false
      });
  }


  openRelationNoteMenu(sourceId: number, targetId: number) {
    this.noteDialog.open(NoteDialogRelationComponent,  {
      data: {
        targetCardPatternId: targetId,
        sourceCardPatternId: sourceId,
      },
      autoFocus: false
    });
  }

  public canConnectLeft() {
    return this.cws.canConnectLeft(this.selectedWebElement.posX, this.selectedWebElement.posY, this.selectedWebElement.id);
  }

  public canConnectRight() {
    return this.cws.canConnectRight(this.selectedWebElement.posX, this.selectedWebElement.posY, this.selectedWebElement.id);
  }

  public canConnectBottom() {
    return this.cws.canConnectBottom(this.selectedWebElement.posX, this.selectedWebElement.posY, this.selectedWebElement.id);
  }

  public hasCardNote(cardId: number): boolean {
    return this.cos.hasCardNote(cardId);
  }

  getCardNoteText(cardId: number) {
    const cardNote = this.customObjects.cardNotes.find(co => co.sourceId === cardId);
    return cardNote.text;
  }

  getCardNoteType(cardId: number) {
    const cardNote = this.customObjects.cardNotes.find(co => co.sourceId === cardId);
    return cardNote.type;
  }

  hasRelationNote(relation: GameDesignRelation) {
    return this.cos.hasRelationNote(relation.sourceId, relation.targetId);
  }

  private hasSelectedNote() {
    return this.cos.hasRelationNote(this.selectedConnection.sourceId, this.selectedConnection.targetId);
  }

  getRelationNoteText(relation: GameDesignRelation) {
    const relationNote = this.customObjects.relationNotes.find(
      note => (note.sourceId === relation.sourceId && note.targetId === relation.targetId)
    );
    return relationNote.text;
  }

  getRelationNoteType(relation: GameDesignRelation) {
    const relationNote = this.customObjects.relationNotes.find(
      note => (note.sourceId === relation.sourceId && note.targetId === relation.targetId)
    );
    return relationNote.type;
  }

  getGameDesignRelations() {
    return this.gameDesign.gameDesignRelations;
  }

  getGameDesignProblemRelations() {
    return this.gameDesign.gameDesignProblemRelations;
  }

  calculateRelationNoteCoordinate(relation: GameDesignRelation){
    const coordinate = this.calculateRelationCoordinates(relation.sourceId, relation.targetId);
    let x = coordinate.x;
    let y = coordinate.y;
    if (coordinate.pos === 'bottom') {
      y = y + 20;
      x = x - 71;
    }else if (coordinate.pos === 'left' || coordinate.pos === 'right'){
      y = y + 20;
      x = x - 23;
    }
    return {x, y};
  }

  calculateRelationDeleteCoordinates(pos = '') {
    let x: number;
    let y: number;
    if (pos === 'bottom') {
        x = this.selectedConnection.posX - 40;
        y = this.selectedConnection.posY + 35;
        if (this.hasSelectedNote()) {
          x = x - 60;
          y = y + 25;
        }
    } else {
        // pos === 'left' or 'right'
        x = this.selectedConnection.posX + 60;
        y = this.selectedConnection.posY + 50;
        if (this.hasSelectedNote()) {
            y = y + 85;
        }
    }
    return {x, y};
  }

    calculateRelationNoteMenuCoordinates(pos = '') {
        let x: number;
        let y: number;
        if (pos === 'bottom') {
            x = this.selectedConnection.posX + 55;
            y = this.selectedConnection.posY + 25;
            if (this.hasSelectedNote()) {
                x = x + 66;
                y = y + 27;
            }
        } else {
            // pos === 'left' or 'right'
            x = this.selectedConnection.posX + 50;
            y = this.selectedConnection.posY - 50;
            if (this.hasSelectedNote()) {
                y = y + 0;
            }
        }
        return {x, y};
    }
}
