export enum ConnectorMessageType {
  SAVE_MESSAGE = 'SAVE_MESSAGE',
  REMOVE_CARD_GAMEDESIGN = 'REMOVE_CARD_GAMEDESIGN',
  REMOVE_CARD_CARDWEB = 'REMOVE_CARD_CARDWEB',
  ADD_CARD_GAMEDESIGN = 'ADD_CARD_GAMEDESIGN',
  ADD_CONNECTION = 'ADD_CONNECTION',
  DELETE_CONNECTION = 'DELETE_CONNECTION',
  ADD_CUSTOM_OBJECT = 'ADD_CUSTOM_OBJECT',
  DELETE_CUSTOM_OBJECT = 'DELETE_CUSTOM_OBJECT',
  MOVE_CARD = 'MOVE_CARD',
  ADD_CARD_CARDWEB = 'ADD_CARD_CARDWEB',
  RESET_EDITOR = 'DELETE_CUSTOM_OBJECT'
// TODO: Implement real reset enum name in backend. This is a temporary fix in order to work with the reset
}
