import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {CardStoreService} from '../../shared/card-store.service';
import {CardPattern} from '../../shared/card-pattern';
import {DeckType} from '../../shared/card-deck';
import {CustomObjectsService} from '../../shared/custom-objects.service';
import {NoteType, RelationNote} from '../../shared/custom-objects';
import {ColorFactory} from '../../shared/color-factory';
import {GameDesignService} from '../../design-recommender/shared/game-design.service';

@Component({
  selector: 'em-note-dialog-relation',
  templateUrl: './note-dialog-relation.component.html',
  styleUrls: ['./note-dialog-relation.component.css']
})
export class NoteDialogRelationComponent implements OnInit {

  get selectedColor(): string {
    return ColorFactory.getColor(this.note.type) || '#000000';
  }


  constructor(
    private dialogRef: MatDialogRef<NoteDialogRelationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { sourceCardPatternId: number, targetCardPatternId: number },
    private gs: GameDesignService,
    private cos: CustomObjectsService,
    private cs: CardStoreService,
  ) {
    this.targetCardPatternId = data.targetCardPatternId;
    this.sourceCardPatternId = data.sourceCardPatternId;
  }

  @Input() sourceCardPatternId: number;
  @Input() targetCardPatternId: number;
  sourceCardPattern$: Observable<CardPattern>;
  targetCardPattern$: Observable<CardPattern>;

  protected readonly ColorFabric = ColorFactory;
  readonly NoteType = NoteType;
  readonly DeckType = DeckType;

  // Sticky Note Colors
  note: RelationNote;

  ngOnInit(): void {
    if (this.gs.hasGameDesignRelation(this.sourceCardPatternId, this.targetCardPatternId)
      || this.gs.hasGameDesignProblemRelation(this.sourceCardPatternId, this.targetCardPatternId)) {
      this.sourceCardPattern$ = this.cs.getPatternCardWithCell(234754, this.sourceCardPatternId, DeckType.pattern);
      this.targetCardPattern$ = this.cs.getPatternCardWithCell(234754, this.targetCardPatternId, DeckType.pattern);
      if (this.cos.hasRelationNote(this.sourceCardPatternId, this.targetCardPatternId)) {
        const noteTemp = this.cos.getRelationNote(this.sourceCardPatternId, this.targetCardPatternId);
        this.note = {
          sourceId: noteTemp.sourceId,
          targetId: noteTemp.targetId,
          text: noteTemp.text,
          type: noteTemp.type
        };
      } else {
        this.note = {
          sourceId: this.sourceCardPatternId,
          targetId: this.targetCardPatternId,
          text: '',
          type: NoteType.RELATION_ELEMENT
        };
      }
    } else {
      throw new Error('Trying to add relationNote to non existing relation');
    }
  }

  saveNote(): void {
    this.cos.addOrUpdateRelationNote(this.note.text.trim(), this.sourceCardPatternId, this.targetCardPatternId);
    this.dialogRef.close();
  }

  getColor(type: NoteType) {
    return ColorFactory.getColor(type);
  }

  getCardNoteText(cardPattern: CardPattern) {
    if (this.hasCardNote(cardPattern)) {
      const cardNote = this.cos.getCardNote(cardPattern.id);
      return cardNote.text.trim();
    }else {
      return cardPattern.summary.trim();
    }
  }

  hasCardNote(cardPattern: CardPattern) {
    return this.cos.hasCardNote(cardPattern.id);
  }

  getCardNoteType(cardPattern: CardPattern) {
    if (this.hasCardNote(cardPattern)){
      const cardNote = this.cos.getCardNote(cardPattern.id);
      return cardNote.type;
    }else{
      return NoteType.MISSING_NOTE;
    }
  }

  hasRelationNote() {
    return this.cos.hasRelationNote(this.sourceCardPatternId, this.targetCardPatternId);
  }

  removeRelationNote() {
    this.cos.removeRelationNote(this.sourceCardPatternId, this.targetCardPatternId);
  }

  toggleChange(event: any) {
    const toggle = event.source;
    if (toggle && event.value.some((item: any) => item === toggle.value)) {
      toggle.buttonToggleGroup.value = [toggle.value];
    }
  }
}
