import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {CardStoreService} from '../../shared/card-store.service';
import {CardPattern} from '../../shared/card-pattern';
import {DeckType} from '../../shared/card-deck';
import {CustomObjectsService} from '../../shared/custom-objects.service';
import {CardNote, CardNoteType, NoteType} from '../../shared/custom-objects';
import {ColorFactory} from '../../shared/color-factory';

@Component({
  selector: 'em-note-dialog-element',
  templateUrl: './note-dialog-element.component.html',
  styleUrls: ['./note-dialog-element.component.css']
})

export class NoteDialogElementComponent implements OnInit {

  get selectedColor(): string {
    return ColorFactory.getColor(this.note.type) || '#000000';
  }


  constructor(
    private dialogRef: MatDialogRef<NoteDialogElementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { sourceCardDeckId: number, sourceCardPatternId: number, sourceCardDeckType: DeckType },
    private cs: CardStoreService,
    private cos: CustomObjectsService,
  ) {
    this.sourceCardDeckType = data.sourceCardDeckType;
    this.sourceCardDeckId = data.sourceCardDeckId;
    this.sourceCardPatternId = data.sourceCardPatternId;
  }

  @Input() sourceCardDeckId: number;
  @Input() sourceCardPatternId: number;
  @Input() sourceCardDeckType: DeckType;
  sourceCardPattern$: Observable<CardPattern>;
  protected readonly ColorFabric = ColorFactory;

  // Sticky Note Colors
  note: CardNote;

  cardNoteTypes: CardNoteType[] = [
    NoteType.SOLUTION_ELEMENT,
    NoteType.EXISTING_ELEMENT,
    NoteType.PROBLEM_ELEMENT,
    NoteType.PROBLEM_MISFIT,
  ];

  typeNameMap: { [key in CardNoteType]: string } = {
    [NoteType.SOLUTION_ELEMENT]: 'Lösung',
    [NoteType.EXISTING_ELEMENT]: 'Vorhanden',
    [NoteType.PROBLEM_ELEMENT]: 'Problem',
    [NoteType.PROBLEM_MISFIT]: 'Misfit',
    [NoteType.MISSING_NOTE]: 'Keine Notiz'
  };

  readonly NoteType = NoteType;
  readonly DeckType = DeckType;

  getTypeName(type: CardNoteType): string {
    return this.typeNameMap[type];
  }

  get filteredCardNoteTypes() {
    return this.cardNoteTypes.filter(type => type !== NoteType.PROBLEM_MISFIT);
  }

  ngOnInit(): void {
    this.sourceCardPattern$ = this.cs.getPatternCardWithCell(
      this.sourceCardDeckId,
      this.sourceCardPatternId,
      this.sourceCardDeckType
    );
    if (this.cos.hasCardNote(this.sourceCardPatternId)) {
      const noteTemp = this.cos.getCardNote(this.sourceCardPatternId);
      this.note = {
        sourceId: noteTemp.sourceId,
        text: noteTemp.text,
        type: noteTemp.type
      };
    } else {
      this.note = {
        sourceId: this.sourceCardPatternId,
        text: '',
        type: this.sourceCardDeckType === DeckType.pattern ? NoteType.SOLUTION_ELEMENT : NoteType.PROBLEM_MISFIT
      };
    }
  }

  hasCardNote(){
    return this.cos.hasCardNote(this.sourceCardPatternId);
  }

  changeColor(type: CardNoteType): void {
    this.note.type = type;
  }

  saveNote(): void {
    this.cos.addOrUpdateCardNote(this.note.type, this.note.text, this.sourceCardPatternId);
  }

  getColor(type: CardNoteType) {
      return ColorFactory.getColor(type);
  }

  removeNote() {
    this.cos.removeCardNote(this.sourceCardPatternId);
  }
}
