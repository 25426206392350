import {NoteType} from './custom-objects';

enum NoteColor {
  white = '#FFFFFF',
  yellow = '#FFD100',
  light_yellow = '#ffefa7',
  blue = '#009ccd',
  light_blue = '#95d5e9',
  green = '#039d75',
  light_green = '#6dd7bc',
  red = '#e7011a',
  light_red = '#f299a3'
}

export class ColorFactory {

  private static colorMap: { [key in NoteType]: string } = {
    [NoteType.EXISTING_ELEMENT]: NoteColor.light_blue,
    [NoteType.SOLUTION_ELEMENT]: NoteColor.light_green,
    [NoteType.PROBLEM_ELEMENT]: NoteColor.light_red,
    [NoteType.PROBLEM_MISFIT]: NoteColor.light_red,
    [NoteType.RELATION_ELEMENT]: NoteColor.light_yellow,
    [NoteType.MISSING_NOTE]: NoteColor.white,
  };

  public static getColor(type: NoteType) {
    return this.colorMap[type];
  }
}

