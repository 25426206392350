<div class="dialog" mat-dialog-content>
  <div *ngIf="sourceCardPattern$ | async as cardPattern; else loading" class="dialog-containers editorElement">
    <!-- Card Content -->
    <em-pattern-card
         [cardPatternId]="cardPattern.id"
         [cardDeckId]="cardPattern.cardDeckId"
         [cardDeckType]="cardPattern.cardDeckType">
    </em-pattern-card>
  </div>
  <div class="dialog-containers">
    <div class="note" [ngStyle]="{ backgroundColor: selectedColor }">
      <!-- Note Input Section -->
      <div class="note-input">
        <textarea *ngIf="sourceCardDeckType === DeckType.pattern" class="note-textarea note-textarea-element" style="resize: none" [(ngModel)]="this.note.text"
                  placeholder="Schreiben Sie hier..." maxlength="363" emAutofocus></textarea>
        <textarea *ngIf="sourceCardDeckType === DeckType.problem" class="note-textarea note-textarea-misfit" style="resize: none" [(ngModel)]="this.note.text"
                  placeholder="Schreiben Sie hier..." maxlength="363" emAutofocus></textarea>
      </div>
    </div>
    <br>
    <div class="color-buttons" *ngIf="sourceCardDeckType === DeckType.pattern">
      <button
              *ngFor="let type of filteredCardNoteTypes"
              mat-flat-button
              [ngStyle]="{ backgroundColor: getColor(type) }"
              (click)="changeColor(type)">
        {{ getTypeName(type) }}
      </button>
    </div>
    <!--div class="color-buttons" *ngIf="sourceCardDeckType === DeckType.problem">
      <button
              mat-flat-button
              [ngStyle]="{ backgroundColor: getColor(NoteType.PROBLEM_MISFIT) }"
              (click)="changeColor(NoteType.PROBLEM_MISFIT)">
        {{ getTypeName(NoteType.PROBLEM_MISFIT) }}
      </button>
    </div-->
  </div>
</div>
<br>
<mat-divider></mat-divider>
<div mat-dialog-actions class="actions">
  <button *ngIf="this.hasCardNote()" type="button" mat-button mat-dialog-close color="accent" (click)="removeNote()">Notiz löschen</button>
  <button type="button" mat-button color="primary" mat-dialog-close>Schließen</button>
  <button  type="submit"  mat-button  color="primary"  (click)="saveNote()"  mat-dialog-close>  {{ this.hasCardNote() ? 'Notiz ändern' : 'Notiz anlegen' }}</button>
</div>

<ng-template #loading>
  <!-- Skeleton Loading -->
  <div class="skeleton-container">
    <div class="skeleton skeleton-title"></div>
    <div class="skeleton skeleton-image"></div>
    <div class="skeleton skeleton-text"></div>
    <div class="skeleton skeleton-input"></div>
  </div>
</ng-template>
