/**
 * CustomObjects are all objects like post its which the user can add oder customize in addition to pattern cards
 */
export interface CustomObjects {
  id: number;
  cardNotes: CardNote[];
  relationNotes: RelationNote[];
}

export interface CardNote {
  type: CardNoteType;
  text: string;
  // References the pattern or misfit card the note ist attached to
  sourceId: number;
}
export interface RelationNote {
  type: NoteType.RELATION_ELEMENT;
  text: string;
  // References the source pattern or misfit card the note ist attached to
  sourceId: number;
  // References the target pattern or misfit card the note ist attached to
  targetId: number;
}

export enum NoteType{
  SOLUTION_ELEMENT,
  PROBLEM_ELEMENT,
  PROBLEM_MISFIT,
  EXISTING_ELEMENT,
  RELATION_ELEMENT,
  MISSING_NOTE = 5
}

export type CardNoteType = NoteType.EXISTING_ELEMENT
  | NoteType.PROBLEM_ELEMENT
  | NoteType.SOLUTION_ELEMENT
  | NoteType.PROBLEM_MISFIT
  | NoteType.MISSING_NOTE;
